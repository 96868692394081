<template>
  <div class="header__access">
    <div class="header__access--group" @click="isOpen = !isOpen">
      <span class="header__access--info">
        {{ user.name }}
      </span>
      <img class="header__access--avatar" :src="user.user.avatar" alt="" />
    </div>

    <div
      @click="isOpen = false"
      class="header__access--nav"
      :class="{ open: isOpen }"
    >
      <!--
      <router-link
        to="/perfil"
        class="header__access--anchor"
        title="Meus dados"
      >
        Meus dados
      </router-link>
      -->
      <span @click="logout()" class="header__access--anchor">
        Sair do sistema
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['user'])
  },
  methods: {
    ...mapActions('user', ['logout'])
  },
  data: () => ({
    isOpen: false
  }),
  watch: {
    '$route.path' () {
      this.isOpen = false
    }
  }
}
</script>
